<template>
  <div class="member-center">
    <div class="header">
      <div class="slider-banner banner">
        <swiper class="swiper-wrapper" :options="swiperVip" ref="mySwiper">
          <swiperSlide
            class="swiper-slide memberBg"
          >
            <div class="name">普通会员</div>
            <div class="discount">
              可享受商品折扣: {{ 100 / 10 }}折
              <span class="iconfont icon-zhekou"></span>
            </div>
            <div class="nav acea-row">
              <div class="item">
                <div class="num">10</div>
                <div>积分数</div>
              </div>
              <div class="item">
                <div class="num">100</div>
                <div>余额</div>
              </div>
            </div>
            <!-- <div class="lock">
              <span class="iconfont icon-quanxianguanlisuozi"></span>该会员等级尚未解锁
            </div>
            <div class="lock">
              <span class="iconfont icon-xuanzhong1"></span>已解锁更高等级
            </div> -->
          </swiperSlide>
        </swiper>
      </div>
    </div>
    <div class="wrapper">
      <div class="title acea-row row-between-wrapper">
        <div>
          <span class="iconfont iconhuiyuan2"></span>会员升级要求
        </div>
        <div class="num">
          <span class="current">0</span>
          /2
        </div>
      </div>
      <div class="list">
        <div class="item">
          <div class="top acea-row row-between-wrapper">
            <div class="name">
              满足积分999分
              <span class="iconfont icon-wenti"></span>
            </div>
            <div>已满足条件</div>
          </div>
          <div class="cu-progress">
            <div class="bg-red" style=" width: 20%"></div>
          </div>
          <div class="experience acea-row row-between-wrapper">
            <div>还需要989经验</div>
            <div>
              <span class="num">28.8</span>
              /100
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "Poster",
  components: {
    swiper,
    swiperSlide
  },
  props: {},
  data: function() {
    return {
      vipList: [], //等级列表
      vipRequire: [], //等级要求
      vipComplete: [], //完成情况
      taskCount: 0, //任务数
      grade: 0, //当前会员等级
      swiperVip: {
        speed: 1000,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        // loop: true,
        coverflowEffect: {
          rotate: 0, // 旋转的角度
          stretch: -20, // 拉伸   图片间左右的间距和密集度
          depth: 100, // 深度   切换图片间上下的间距和密集度
          modifier: 2, // 修正值 该值越大前面的效果越明显
          slideShadows: false // 页面阴影效果
        },
        observer: true,
        observeParents: true
      },
      loading: false,
      growthValue: true,
      illustrate: "",
      activeIndex: 0
    };
  },
 
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.member-center {
  height: 100vh;
}

.row-between-wrapper {
  display: flex;
  justify-content: space-between;
}

.member-center .header {
  background-color: #232323;
  width: 100%;
  padding: 25px 0;
}

.member-center .header .slider-banner {
  height: 164px;
}

.member-center .header .slider-banner .swiper-slide {
  width: 318px !important;
  height: 164px;
  border-radius: 8px;
  background: url("../../../../public/static/images/vip/vip-lv0.png");
  color: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.member-center .header .slider-banner .swiper-slide img {
  width: 44.5px;
  height: 54px;
  display: block;
  position: absolute;
  right: 30px;
}

.member-center .header .slider-banner .swiper-slide .name {
  font-size: 23px;
  font-weight: bold;
  padding: 16.5px 0 0 17.5px;
}

.member-center .header .slider-banner .swiper-slide .discount {
  font-size: 14px;
  font-weight: bold;
  margin: 1px 0 0 17.5px;
}

.member-center .header .slider-banner .swiper-slide .discount .iconfont {
  margin-left: 5px;
  font-size: 15px;
}

.member-center .header .slider-banner .swiper-slide .nav {
  margin-top: 24px;
  display: flex;
}

.member-center .header .slider-banner .swiper-slide .nav .item {
  flex: 1;
  -webkit-flex: 1;
  -o-flex: 1;
  -ms-flex: 1;
  text-align: center;

  color: rgba(255, 255, 255, 0.6);
  position: relative;
}

.member-center .header .slider-banner .swiper-slide .nav .item .num {
  font-size: 20px;
  color: #fff;
  font-family: "GuildfordProBook 5";
}

.member-center .header .slider-banner .swiper-slide .nav .item ~ .item::before {
  position: absolute;
  width: 1px;
  height: 16px;

  background-color: rgba(255, 255, 255, 0.6);
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.member-center .header .slider-banner .swiper-slide .lock {
  font-size: 13px;
  margin: 36.5px 0 0 17.5px;
}

.member-center .header .slider-banner .swiper-slide .lock .iconfont {
  font-size: 16.5px;
  margin-right: 7.5px;

  vertical-align: -2px;
}

.member-center .wrapper {
  background-color: #fff;
  padding-bottom: 8px;
  margin-bottom: 10px;
}

.member-center .wrapper .title {
  height: 49px;
  line-height: 49px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: bold;
  color: #282828;
  display: flex;
  justify-content: space-between;
}

.member-center .wrapper .title .iconfont {
  color: #ffae06;
  font-weight: normal;
  font-size: 20px;
  margin-right: 6px;

  vertical-align: -1px;
}

.member-center .wrapper .title .num {
  font-size: 14px;
  color: #999;
}

.member-center .wrapper .title .num .current {
  color: #ffae06;
}

.member-center .wrapper .list .item {
  width: 345px;
  height: 92px;
  background-color: #f9f9f9;
  margin: 0 auto 10px auto;

  padding: 13.5px 0 11px 0;
  border-radius: 6px;
}

.member-center .wrapper .list .item .top {
  padding-right: 13.5px;
  font-size: 13px;
  color: #999;
}

.member-center .wrapper .list .item .top .name {
  border-left: 3px solid #ffae06;
  padding-left: 10px;
  font-size: 14px;

  color: #282828;
  font-weight: bold;
}

.member-center .wrapper .list .item .top .name .iconfont {
  color: #999;
  font-size: 15px;
  vertical-align: -1px;

  margin-left: 5px;
}

.member-center .wrapper .list .item .cu-progress {
  overflow: hidden;
  height: 6px;
  background-color: #eee;
  width: 318px;

  border-radius: 10px;
  margin: 17.5px auto 0 auto;
}

.member-center .wrapper .list .item .cu-progress .bg-red {
  width: 0;
  height: 100%;
  transition: width 0.6s ease;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;

  background-color: #ffaa29;
  border-radius: 10px;
}

.member-center .wrapper .list .item .experience {
  margin-top: 8.5px;
  padding: 0 13.5px;
  font-size: 12px;
  color: #999;
}

.member-center .wrapper .list .item .experience .num {
  color: #ffad07;
}

.member-center .growthValue {
  background-color: #fff;
  border-radius: 8px;
  position: fixed;
  top: 133px;
  left: 50%;
  width: 280px;

  height: 370px;
  margin-left: -140px;
  z-index: 99;
  transform: translate3d(0, -200%, 0);
  -webkit-transform: translate3d(0, -200%, 0);
  -ms-transform: translate3d(0, -200%, 0);
  -moz-transform: translate3d(0, -200%, 0);

  -o-transform: translate3d(0, -200%, 0);

  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.member-center .growthValue.on {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.member-center .growthValue .pictrue {
  width: 100%;
  height: 128.5px;
  position: relative;
}

.member-center .growthValue .pictrue img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
}

.member-center .growthValue .conter {
  padding: 0 17.5px;
  font-size: 15px;
  color: #333;
  margin-top: 29px;
  line-height: 1.5;

  height: 175px;
  overflow: auto;
}

.member-center .growthValue .pictrue .iconfont {
  position: absolute;
  font-size: 32.5px;
  color: #fff;
  top: 387.5px;
  left: 50%;

  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
</style>